import React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/Layout";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
`;

const Page = ({ data }) => {
  return (
    <Layout>
      <section className="md:pb-30 pt-12 pb-20 md:pt-16">
        <div className="container">
          <StyledContent>
            <h1>Legal Disclaimer</h1>
            <p>
            Nikkael Home Loans hereby authorizes you to view and print information on this web site subject to the fact that it is used for informational and non-commercial purposes.</p>
            <p>It is our intention that data provided on a subject is of a general nature. Our web site does not represent an exhaustive treatment of subjects nor is the information intended to constitute accounting, tax, legal, consulting or other professional advice.</p>

            <p>Prior to making any decision or taking any action, we kindly request you to contact your tax or legal advisors.</p>

            <p>Please use this document and information at your own risk. The content of this site is copyrighted and therefore any unauthorized use of any materials on this web site may violate copyright, trademark, and other laws.</p>

            <p>Materials on this web site may not be modified, reproduced, or publicly displayed, distributed or performed for any public or commercial purposes prior to our approval.</p>

            <p>All loans are subject to credit and underwriting approval.  Products are available in California only.  The programs advertised on this landing page or anywhere else within this website are not a commitment to lend nor a guarantee of approval.  </p>

            <p>Programs, rates and other terms are subject to change in Nikkael Home Loans’ sole and absolute discretion without notice.  It is important to make an informed decision when selecting and using a loan product.  Make sure to compare loan products and types when making a financing decision.  Please seek advice from a tax professional.  </p>

            <p>The reverse mortgage borrower must meet all loan obligations including, without limitation, living in the property as the principal residence, maintaining the home and paying all property charges including, without limitation, property taxes, fees and hazard insurance premiums.  If the reverse mortgage borrower does not meet these loan obligations, then the loan will need to be repaid.  This website is not a part of, or any way associated with, HUD or FHA and has not been approved by any government agency.</p>
          </StyledContent>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
